<template>
  <section v-bind="initData">
    <div class="section-help section" id="help" v-if="data && lang">
      <div class="section-help-wrap">
        <div class="section-help-header">
          <div class="section-help-header-wrap main">
            <div
              class="section-help-header-main"
              :style="`background-image: url(${this.$global.newUrl(
                data.bannerLink
              )})`"
            ></div>
          </div>
        </div>
        <div class="section-help-title">
          <h2 class="section-help-title-top sec-font">{{ data.title }}</h2>
          <h3 class="section-help-title-sub">{{ data.subtitle }}</h3>
        </div>
        <div class="section-help-list">
          <div class="section-help-list-wrap main">
            <div class="section-help-list-main">
              <div class="section-help-list-content">
                <h3 class="section-help-list-head">{{ data.faqLabel }}</h3>
                <div
                  class="section-help-list-content-wrap"
                  v-if="data.questions"
                >
                  <SingleAccordion
                    v-for="(faq, index) in data.questions"
                    :key="index"
                    :title="faq.ask"
                    :content="faq.reposnse"
                  ></SingleAccordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from "../../api";
import store from "../../store/index.js";
import SingleAccordion from "./SingleAccordion.vue";
export default {
  components: { SingleAccordion },
  data: () => {
    return {
      data: null,
      lang: null,
      locale: null,
    };
  },
  metaInfo: function () {
    const title = this.locale ? "- " + this.locale.help.description : "";
    return {
      title: `Models1A ${title}`,
    };
  },
  methods: {
    getLang: async function () {
      if (store.getters["iflanguage"]) {
        this.lang = await store.getters["dataLang"];
        this.locale = await store.getters["localeLang"];
      }
    },
    getData: async function () {
      let response = await api.get(`${process.env.VUE_APP_API_HOST}help`);
      this.data = response.data;
      this.data.bannerLink =
        "https://new-web-site-bucket.s3.us-east-1.amazonaws.com/website/blog/bannerBlog.jpeg";
    },
  },
  computed: {
    initData: async function () {
      await this.getLang();
      await this.getData();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.enlace {
  color: blue;
}
.section-help {
  margin-top: 70px;
  text-align: center;
  &-header {
    height: 200px;
    &-wrap {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    &-main {
      background-color: $white_smoke;
      height: calc(100% - #{$mpadding * 2});
      width: calc(100% - #{$mpadding * 2});
      border-radius: $mradius;
      background-size: cover;
      background-position: top 40% center;
      box-shadow: $dshadow;
    }
  }
  &-title {
    margin-top: -$mpadding * 2.2;
    &-top {
      font-size: 32px;
      font-weight: normal;
      color: $primary_color;
    }
    &-sub {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.462;
      color: #5b5957;
    }
  }
  &-list {
    &-main {
      display: block;
      padding: $mpadding;
      text-align: left;
    }
    &-content {
      width: 100%;
    }
    &-aside,
    &-content {
      width: 100%;
      box-shadow: $dshadow;
      border-radius: $mradius;
      margin-bottom: $mpadding;
    }
    &-head {
      padding: $mpadding/2 $mpadding;
      background-color: $primary_color;
      color: $white;
      font: {
        family: $sec_font;
        weight: normal;
        size: 120%;
      }
      border-radius: $mradius/2 $mradius/2 0 0;
    }
  }
  @media (min-width: $tablet_width) {
    &-header {
      height: 239.91px;
    }
    &-title {
      margin-top: -$mpadding * 1.7;
      &-top {
        font-size: 42px;
      }
      &-sub {
        font-size: 16px;
        line-height: 1.625;
      }
    }
  }
}
</style>
